<template>
  <div>
    <v-app-bar
      id="core-toolbar"
      color="env"
    >
      <div class="white--text">
        v{{ version }} / {{ $store.state.user.profile.firstName }} {{ $store.state.user.profile.lastName }} {{ envInfo }}
      </div>

      <v-spacer />
      <v-toolbar-items class="align-center">
        <v-text-field
          v-model="keyword"

          prepend-icon="search"
          label="Search..."
          hide-details
          background-color="white"
          solo-inverted
          color="yellow"
          class="hidden-sm-and-down black--text"
          @keyup.enter="search"
        />
        <v-divider
          class="mx-4"
          vertical
        />

        <Language />

        <v-divider
          class="mx-4"
          vertical
        />

        <v-icon
          color="white"
          @click="logout"
        >
          mdi-exit-to-app
        </v-icon>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>

import {
  mapMutations
, mapActions
} from 'vuex'

import { AUTH_LOGOUT } from '../../store/actions/auth'
import { NODE_ENV } from '@/utils/config'
import { version } from '../../../package.json'
import Language from './Language'

export default {
  components: {
    Language
  },
  
  data: () => ({
    title: null,
    responsive: false,
    responsiveInput: false,
    keyword: ''
  }),

  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  computed: {
    version () {
      return version
    },
    envInfo () {
      if (NODE_ENV !== 'production') {
        return ' / ' + NODE_ENV.toUpperCase()
      }
    }
  },
  methods: {

    search (e) {
      console.log('SEARCH')
      this.$router.push({ name: 'SearchResult', query: { keyword: this.keyword } })
    },

    ...mapActions('auth', [
      AUTH_LOGOUT
    ]),
    logout () {
      this.AUTH_LOGOUT().then((resp) => {
        this.$router.push('/login')
      })
    },
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onClick () {
      //
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    }
  }
}
</script>

<style>
    #core-toolbar a {
        text-decoration: none;
    }
    .hidden-sm-and-down .v-icon {
      color: white !important;
    }
    .hidden-sm-and-down .input {
      color: black !important;
    }
    input:focus {
      color: black !important;
    }
</style>
