
import Vue from 'vue'
import { DEFAULT_DATE, FORMAT_DATETIME, DEFAULT_DATETIME } from '@/utils/config'
import { FORMAT_DATETIME_BACKEND } from '../utils/config'

export default (date) => {
  let dateTime = null

  if (Vue.moment(date, DEFAULT_DATE, true).isValid()) {
    dateTime = Vue.moment(date, DEFAULT_DATE)
  } else if (Vue.moment(date, FORMAT_DATETIME_BACKEND, true).isValid()) {
    dateTime = Vue.moment(date, FORMAT_DATETIME_BACKEND)
  } else if (Vue.moment(date, DEFAULT_DATETIME, true).isValid()) {
    dateTime = Vue.moment(date, DEFAULT_DATETIME)
  }

  if (dateTime !== null) {
    return Vue.moment.duration(dateTime - Vue.moment()).humanize(true)
  }

  return date
}
