import ApiService from '@/utils/api.service'
import { API_VERSION } from '@/utils/config'

import { SEARCH } from '../actions/search'

const state = {
  result: []
}

const actions = {
  [SEARCH]: ({ commit, dispatch }, term) => {
    return ApiService.post(`api/${API_VERSION}/search/admin`, { keyword: term })
      .then(resp => {
        commit(SEARCH, resp.data)
      })
  }
}

const mutations = {
  [SEARCH]: (state, item) => {
    state.result = item
  }

}

export default {
  state,
  actions,
  mutations
}
