import ApiService from '@/utils/api.service'
import { API_VERSION } from '@/utils/config'
import Crud from '../crud'
var crud = new Crud('publications')

const state = {
  ...crud.state,
  categories: []
}
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  FETCH_CATEGORIES: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/${API_VERSION}/${state.endpoint}/categories`)
        .then(({ data }) => {
          commit('SET_CATEGORIES', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
const mutations = {
  ...crud.mutations,
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
