import Crud from '../crud'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
import { ADD_MUT, SET_MUT } from '../mutation-types'
import { ADD_BY_TICKET, FETCH_BY_TICKET } from '../actions/ticketAssetManager'
var crud = new Crud('ticketAssetManagers')

const state = {
  ...crud.state
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions,
  [FETCH_BY_TICKET]: ({ commit, state }, id) => {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/${API_VERSION}/ticketAssetManagers/ticket/${id}`)
        .then(({ data }) => {
          commit(SET_MUT, data)
          resolve(data)
        }).catch(err => {
          reject(err)
        })
    })
  },
  [ADD_BY_TICKET]: ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      ApiService
        .post(`api/${API_VERSION}/ticketAssetManagers/ticket/${data.id}`, data.tickets)
        .then(({ data }) => {
          commit(ADD_MUT, data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
