import Crud from '../crud'
import { FETCH } from '../action-types'
import { SET_MUT } from '../mutation-types'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
var crud = new Crud('issuers')

const state = {
  ...crud.state
}
const getters = {
  ...crud.getters
}
const actions = {
  [FETCH]: ({ commit, state }, id) => {
    return ApiService.get(`api/${API_VERSION}/${state.endpoint}/${id}/contact`)
      .then(({ data }) => {
        commit(SET_MUT, data)
      })
  }
}
const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
