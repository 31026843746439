import pick from 'lodash/pick'
import get from 'lodash/get'
import has from 'lodash/has'
import isNil from 'lodash/isNil'

export const cartesian = (a) => Object.entries(a)
  .map(([x, y]) => y.map(z => ({ [x]: z })))
  .reduce((a, b) => (a.length === 0) ? b : a.flatMap(d => b.map(e => [d, e].flat())), [])
  .map(a => Array.isArray(a) ? Object.assign({}, ...a) : a)

export const variants = ({ variables, ...data }) => {
  return variables.length > 0
    ? cartesian(pick(data, variables))
        .map(product => Object.assign({}, data, product))
    : [data]
}

// For issuer
export const rules = function({ constraints, shortName }, product) {
  const message = key => this ? this.$t('Issuers.constraints.message', {
    constraint: this.$t(`Issuers.constraints.${key}`),
    issuer: shortName,
    value: get(constraints, key)
  }) : 'error'
  const exists = key => has(constraints, key) && !isNil(get(constraints, key))
  const gte = (key, value) => !exists(key) || Number(value) >= (get(constraints, key)) || message(key)
  const lte = (key, value) => !exists(key) || Number(value) <= (get(constraints, key)) || message(key)

  return [
    gte('min_maturity_value', get(product, 'maturityValue')),
    lte('max_maturity_value', get(product,'maturityValue')) ,
    gte('min_notional', get(product, 'notional')),
    lte('max_upfront', get(product, 'initialUpfrontFee')),
    lte('max_nb_underlyings', get(product, 'stocksRelation.length', 0))
  ].filter(condition => condition !== true)
}

export const isValid = function(issuer, product) {
  return !issuer.constraints || rules(issuer, product).length === 0
}

// Pricing
export const pricing = {
  key: ({ productFamily, productName }) => `${productFamily}:${productName}`,
  parse: ({ underlying, ...product }) => Object.assign({}, underlying, product),
  products: form => variants(form).map((product, _index) => ({
    ...product,
    _index,
    issuers: product.issuers.filter(issuer => isValid(issuer, product))
  }))
}