import Crud from '../crud'
import { SET_MUT } from '../mutation-types'
import { FETCH_BY_TYPE } from '../actions/template'
import { API_VERSION } from '../../utils/config'
import ApiService from '../../utils/api.service'
var crud = new Crud('templates')

const state = {
  ...crud.state
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions,
  [FETCH_BY_TYPE]: ({ commit, state }, type) => {
    return ApiService.get(`api/${API_VERSION}/templates/type?type=${type}`)
      .then(({ data }) => {
        commit(SET_MUT, data)
      })
  }
}

const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
