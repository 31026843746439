import Crud from '../crud'
import { DELETE_PRODUCT_FILE, PRODUCT_ADD_FILE_MUT } from '../actions/product'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'

var crud = new Crud('products')

const state = {
  ...crud.state,
  search: [],
  selected: []
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions,
  [DELETE_PRODUCT_FILE]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      return ApiService.delete(`api/${API_VERSION}/${state.endpoint}/file/${data.id}`).then(resp => {
      }).catch(err => {
        reject(err)
      })
    })
  },
  async SEND({ commit }, params) {
    const result = await ApiService.post(`api/${API_VERSION}/${state.endpoint}/send_mail_products`, params)
    commit('CLEAR_SELECTED')
    return result
  },
}

const mutations = {
  ...crud.mutations,
  [PRODUCT_ADD_FILE_MUT]: (state, { id, files }) => {
    const index = state.data.findIndex((item) => item.id === id)
    state.data[index].files = files
  },
  SET_SELECTED: (state, items) => {
    state.selected = items
  },
  REMOVE_SELECTED: (state, item) => {
    state.selected = state.selected.filter(({ id }) => id !== item.id)
  },
  CLEAR_SELECTED: state => {
    state.selected = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
