const ordinal = (d) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export default (d, suffix = '') => {
  return [
    `${d}${ordinal(d)}`,
    suffix
  ].filter(string => string).join(' ')
}