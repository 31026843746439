import ApiService from '@/utils/api.service'
import { API_VERSION } from '@/utils/config'
import { FETCH, ADD } from '../action-types'
import { SET_MUT, ADD_MUT } from '../mutation-types'
const endpoint = `api/${API_VERSION}/stocks/adjustment` 

export default {
  state: {
    data: []
  },

  actions: {
    async [FETCH] ({ commit }, params = {}) {
      const res = await ApiService
        .get(endpoint, {
          params
        })
      commit(SET_MUT, res.data)
      return res
    },
  
    async [ADD] ({ commit }, item) {
      const res = await ApiService.post(endpoint, item)
      commit(ADD_MUT, res.data)
      return res
    },

    async VALIDATE ({ commit }, item) {
      const res = await ApiService.put(endpoint, {
        stock_id: item.stock.id,
        datetime: item.datetime,
        adjustment: item.adj
      })
      commit(ADD_MUT, res.data)
      return res
    }
  },

  mutations: {
    SET_MUT(state, payload) {
      state.data = payload
    },

    ADD_MUT(state, payload) {
      const index = state.data
        .findIndex(item => item.datetime === payload.datetime && item.stock.id === payload.stock.id)

      if (index === -1) {
        state.data.unshift(payload)
      } else {
        state.data.splice(index, 1, payload)
      }
    }
  },

  getters: {
    applied: state => state.data.filter(item => item.adj_applied),
    pending: state => state.data.filter(item => !item.adj_applied)
  }
}