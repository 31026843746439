import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from './colors'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: colors
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
