import Crud from '../crud'
import { FETCH } from '../action-types'
import { SET_MUT } from '../mutation-types'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
var crud = new Crud('issuers/groups')

const state = {
  ...crud.state
}
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions
}
const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
