import Crud from '../crud'
import { CUSTODIAN_BY_AM, CUSTODIAN_BY_ISSUER_AND_AM } from '../actions/custodian'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
import Vue from 'vue'
var crud = new Crud('custodians')

const state = {
  ...crud.state,
  custodianLocation: [],
  custodianByAm: []
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions,
  [CUSTODIAN_BY_ISSUER_AND_AM]: ({ commit, dispatch }, { assetManagerId, issuerId }) => {
    return ApiService.get(`api/${API_VERSION}/custodians/am/${assetManagerId}/issuer/${issuerId}`)
      .then(({ data }) => {
        commit(CUSTODIAN_BY_ISSUER_AND_AM, data)
      })
  },
  [CUSTODIAN_BY_AM]: ({ commit, dispatch }, assetManagerId) => {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/${API_VERSION}/custodians/am/${assetManagerId}`)
        .then(({ data }) => {
          commit(CUSTODIAN_BY_AM, data)
          resolve(data)
        })
    })
  }
}

const mutations = {
  ...crud.mutations,
  [CUSTODIAN_BY_ISSUER_AND_AM]: (state, info) => {
    Vue.set(state, 'custodianLocation', info)
  },
  [CUSTODIAN_BY_AM]: (state, info) => {
    Vue.set(state, 'custodianByAm', info)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
