import Crud from '../crud'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
import { SET_ONE_MUT, UPDATE_MUT } from '../mutation-types'
import { FETCH_TEMPLATE, SEND_TEMPLATE_TEST, UPDATE_VALUE_TO_SOLVE } from '../actions/pricing'
var crud = new Crud('pricings')

const state = {
  ...crud.state
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions,
  [FETCH_TEMPLATE]: ({ commit, state }, { pricing_id, template_id }) => {
    return ApiService.get(`api/${API_VERSION}/pricings/preview/${pricing_id}/template/${template_id}`)
      .then(({ data }) => {
        commit(SET_ONE_MUT, data)
      })
  },
  [SEND_TEMPLATE_TEST]: ({ commit, state }, { pricing_id, template_id, user_ids }) => {
    return ApiService.post(`api/${API_VERSION}/pricings/send/${pricing_id}`, { user_ids })
      .then(({ data }) => {
        commit(SET_ONE_MUT, data)
      })
  },
  [UPDATE_VALUE_TO_SOLVE]: ({ commit, state }, { pricing_id, issuer_id, data }) => {
    return ApiService.put(`api/${API_VERSION}/pricings/${pricing_id}/issuer/${issuer_id}`, data)
      .then(({ data }) => {
        commit(UPDATE_MUT, data)
      })
  }

}

const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
