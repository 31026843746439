import Crud from '../crud'
var crud = new Crud('assetManagerTeams')

const state = {
  ...crud.state
}
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions
}
const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
