export const FRONT_URL = process.env.VUE_APP_FRONT_URL
export const DOMAIN = process.env.VUE_APP_DOMAIN
export const API_URL = process.env.VUE_APP_API_URL
export const NODE_ENV = process.env.VUE_APP_ENV
export const API_VERSION = 'v1'
export const CLIENT_APP = 'AtlanticAdmin'
export const FORMAT_DATE = 'DD/MM/YYYY'
export const FORMAT_DATETIME_BACKEND = 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'
export const DEFAULT_DATE = 'YYYY-MM-DD'
export const DEFAULT_DATETIME = 'YYYY-MM-DDTHH:mm:ssZ'
export const FORMAT_DATETIME = 'MM/DD/YYYY HH:mm'
