import ApiService from '@/utils/api.service'
import { AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS, CHECK_AUTH } from '../actions/auth'
import { CLIENT_APP } from '@/utils/config'
import qs from 'qs'
import JwtService from '@/utils/jwt.service'

export default {
  state: {
    user: {},
    accessToken: JwtService.getToken() || '',
    refreshToken: JwtService.getRefreshToken() || ''
  },

  getters: {
    isAuthenticated: state => !!state.accessToken
  },

  actions: {
    [AUTH_REQUEST]: ({ commit, dispatch }, credentials) => {
      return new Promise((resolve, reject) => {
        ApiService
          .post(`auth/oauth2/token?grant_type=password&client_id=${CLIENT_APP}`, qs.stringify(credentials), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(resp => {
            JwtService.saveToken(resp.data.access_token, resp.data.refresh_token)
            ApiService.setHeader()
            commit(AUTH_SUCCESS, resp.data)
            resolve(resp)
          })
          .catch(err => {
            commit('auth/' + AUTH_LOGOUT, err, { root: true })
            reject(err)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_LOGOUT)
        resolve()
      })
    },
    [CHECK_AUTH] ({ commit, state }) {
      if (state.refreshToken) {
        return new Promise((resolve, reject) => {
          ApiService
            .post('auth/oauth2/token', qs.stringify({
              grant_type: 'refresh_token',
              client_id: CLIENT_APP,
              refresh_token: state.refreshToken
            }))
            .then(resp => {
              JwtService.saveToken(resp.data.access_token, resp.data.refresh_token)
              ApiService.setHeader()
              commit(AUTH_SUCCESS, resp.data)
              resolve(resp)
            })
            .catch(err => {
              commit(AUTH_LOGOUT)
              reject(err)
            })
        })
      } else {
        console.log('no token')
        commit(AUTH_LOGOUT)
        Promise.reject('no_token')
      }
    }
  },

  mutations: {
    [AUTH_SUCCESS] (state, data) {
      state.refreshToken = data.refresh_token
      state.accessToken = data.access_token
    },
    [AUTH_LOGOUT] (state) {
      JwtService.destroyToken()
      ApiService.removeHeader()
      state.refreshToken = null
      state.accessToken = null
    }
  }
}
