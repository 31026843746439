import { SET_MY_USER, USER_ME_REQUEST, USER_RESET } from '../actions/user'
import Vue from 'vue'
import get from 'lodash/get'
import ApiService from '@/utils/api.service'
import { API_VERSION } from '@/utils/config'
import { loadLanguageAsync } from '@/i18n'
import Crud from '../crud'
var crud = new Crud('users')

const state = {
  profile: {},
  ...crud.state
}

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.id,
  language: (state) => state?.profile?.settings?.language || 'en',
  authorizedRights: (state) => state?.profile?.role?.rights,
  hasRight: (state, getters) => right => (getters.authorizedRights?? []).includes(right)
}

const actions = {
  [USER_ME_REQUEST]: ({ commit }) => {
    ApiService.get(`api/${API_VERSION}/users/me`)
      .then(res => {
        const language = get(res.data, 'settings.language', 'en')
        loadLanguageAsync(language)
        commit(SET_MY_USER, res.data)
      })
      .catch(error => {
        console.error(error)
        // if resp is unauthorized, logout, to
        commit(USER_RESET)
      })
  },
  SAVE_SETTING: ({ commit }, obj) => {
    commit('SET_SETTING', obj)
    ApiService.post(`api/${API_VERSION}/user-settings/`, obj)
  },
  ...crud.actions
}

const mutations = {
  [SET_MY_USER]: (state, resp) => {
    Vue.set(state, 'profile', resp)
  },
  SET_SETTING: (state, { key, value}) => {
    Vue.set(state, `profile.settings.${key}` , value)
  },
  [USER_RESET]: (state) => {
    state.profile = {}
  },
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
