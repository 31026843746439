import Vue from 'vue'
import { DEFAULT_DATE, FORMAT_DATE } from '@/utils/config'

const formatter = (date, format = null) => {
  if (Vue.moment(date, DEFAULT_DATE, true).isValid()) {
    return Vue.moment(date, DEFAULT_DATE).format(format ?? FORMAT_DATE)
  }

  return Vue.moment(date, DEFAULT_DATE).format(format ?? FORMAT_DATE)
}

export default (date, format) => {
  if (Array.isArray(date)) {
    return date.map(d => formatter(d, format)).join(' - ')
  }

  return formatter(date, format)
}
