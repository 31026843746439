import Crud from '../crud'
var crud = new Crud('product-schedule-dates')
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
import { UPDATE_MUT } from '../mutation-types'

const state = {
  ...crud.state
}
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,

  PROCESS: ({ state, commit }, {id, send}) => {
    return new Promise((resolve, reject) => {
      return ApiService.put(`api/${API_VERSION}/${state.endpoint}/${id}/process`, {
        send_mail: send
      }).then(({ data }) => {
        commit(UPDATE_MUT, data)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  async REPROCESS_DATE({ state, commit }, form) {
    const { data } = await ApiService.post(`api/${API_VERSION}/${state.endpoint}/process`, form)
    return data
  },

  PREVIEW: ({ state }, { id, content }) => {
    return new Promise((resolve, reject) => {
      return ApiService.post(`api/${API_VERSION}/${state.endpoint}/${id}/template`, {
        content
      }).then(({ data }) => {
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
