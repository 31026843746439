
import Vue from 'vue'
import { DEFAULT_DATE, FORMAT_DATETIME, DEFAULT_DATETIME } from '@/utils/config'
import { FORMAT_DATETIME_BACKEND } from '../utils/config'

export default (date) => {
  if (Vue.moment(date, DEFAULT_DATE, true).isValid()) {
    return Vue.moment(date, DEFAULT_DATE).format(FORMAT_DATETIME)
  }
  if (Vue.moment(date, FORMAT_DATETIME_BACKEND, true).isValid()) {
    return Vue.moment(date, FORMAT_DATETIME_BACKEND).format(FORMAT_DATETIME)
  }
  if (Vue.moment(date, DEFAULT_DATETIME, true).isValid()) {
    return Vue.moment(date, DEFAULT_DATETIME).format(FORMAT_DATETIME)
  }

  return date
}
