<template>
  <v-footer
    id="core-footer"
    absolute
    height="82"
  >
    <v-spacer />
    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
        href="https://www.atlantic-financial.net/"
        target="_blank"
      >Atlantic</a>
    </span>
  </v-footer>
</template>

<script>
export default {
}
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
