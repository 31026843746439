import Vue from 'vue'
import Drawer from './core/Drawer'
import Toolbar from './core/Toolbar'
import Footer from './core/Footer'
import View from './core/View'
import Card from './material/Card'
import Offset from './helper/Offset'

Vue.component('core-drawer', Drawer)
Vue.component('core-toolbar', Toolbar)
Vue.component('core-footer', Footer)
Vue.component('core-view', View)
Vue.component('material-card', Card)
Vue.component('helper-offset', Offset)

