import ApiService from '@/utils/api.service'
import { API_VERSION } from '@/utils/config'
import Vue from 'vue'
import has from 'lodash/has'
import { ADD_MUT, DELETE_MUT, SET_MUT, SET_ONE_MUT, UPDATE_MUT } from './mutation-types'
import { FETCH, FETCH_ONE, UPDATE, DELETE, ADD } from './action-types'

export default class {
  constructor (endpoint) {
    this.mutations = {
      [UPDATE_MUT]: (state, info) => {
        const index = state.data.findIndex((item) => item.id === info.id)
        state.data.splice(index, 1, info)
        if (state.item && state.item.id === info.id) {
          Vue.set(state, 'item', info)
        }
      },
      [DELETE_MUT]: (state, info) => {
        const index = state.data.findIndex((item) => item.id === info)
        state.data.splice(index, 1)
      },
      [ADD_MUT]: (state, data) => {
        state.data.unshift(data)
      },
      [SET_MUT]: (state, info) => {
        Vue.set(state, 'data', info)
      },
      [SET_ONE_MUT]: (state, data) => {
        Vue.set(state, 'item', data)
      },
      SET_TOTAL_COUNT(state, count) {
        Vue.set(state, 'totalCount', count)
      }
    },
    this.state = {
      data: [],
      totalCount: -1,
      item: {},
      endpoint: endpoint
    },
    this.actions = {
      [FETCH_ONE]: ({ commit, state }, id) => {
        return new Promise((resolve, reject) => {
          ApiService.get(`api/${API_VERSION}/${state.endpoint}/${id}`)
              .then(({ data }) => {
                commit(SET_ONE_MUT, data)
                resolve(data)
              })
              .catch(err => {
                reject(err)
              })
        })
      },
      [FETCH]: ({ commit, state }, req = {}) => {
        const { cancelToken, ...params } = req
        return new Promise((resolve, reject) => {
          ApiService.query(`api/${API_VERSION}/${state.endpoint}/`, { params, cancelToken })
            .then(res => {
              if (typeof res !== typeof undefined) {
                const { data, headers } = res
                commit(SET_MUT, data)
                if (has(params, 'limit') && has(headers, 'x-total-count')) {
                  commit('SET_TOTAL_COUNT', Number(headers['x-total-count']))
                  resolve({data, headers})
                } else {
                  resolve(data)
                }
              }
            })
            .catch(err => {
              reject(err)
            })
        })
      },
      [ADD]: ({ commit, dispatch, state }, item) => {
        return new Promise((resolve, reject) => {
          ApiService
            .post(`api/${API_VERSION}/${state.endpoint}/`, item)
            .then(({ data }) => {
              commit(ADD_MUT, data)
              resolve(data)
            })
            .catch(err => {
              reject(err)
            })
        })
      },
      [UPDATE]: ({ commit, dispatch, state }, item) => {
        return new Promise((resolve, reject) => {
          ApiService.put(`api/${API_VERSION}/${state.endpoint}/${item.id}`, item).then(({ data }) => {
            commit(UPDATE_MUT, data)
            resolve(data)
          })
            .catch(err => {
              reject(err)
            })
        })
      },
      [DELETE]: ({ commit, dispatch, state }, data) => {
        return new Promise((resolve, reject) => {
          return ApiService.delete(`api/${API_VERSION}/${state.endpoint}/${data.id}`).then(resp => {
            commit(DELETE_MUT, data.id)
            resolve()
          })
            .catch(err => {
              reject(err)
            })
        })
      }
    }
  }
}
