import Crud from '../crud'

var crud = new Crud('emails')

const state = {
  ...crud.state,
  search: []
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions
}

const mutations = {
  ...crud.mutations

}

export default {
  state,
  getters,
  actions,
  mutations
}
