<template>
  <v-menu offset-y>
    <template #activator="{ on: menu }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            icon
            v-on="{ ...tooltip, ...menu }"
            :loading="loading"
            dark
          >
            <img
              :src="`/flags/${currentLocale}.svg`"
              width="24"
            />
          </v-btn>
        </template>
        <span>Switch Language</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-for="(name, locale) in languages"
        :key="locale"
        @click="changeLanguage(locale)"
      >
        <v-list-item-avatar
          tile
          size="24"
          max-height="18"
          max-width="24"
        >
          <v-img :src="`/flags/${locale}.svg`"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { loadLanguageAsync } from '@/i18n'

export default {
  computed: {
    loading() {
      return !this.$store.getters['user/isProfileLoaded']
    },

    currentLocale() {
      return this.$i18n.locale
    },

    languages() {
      return {
        'en': 'English',
        'fr': 'Français'
      }
    },
  },

  methods: {
    changeLanguage(language) {
      loadLanguageAsync(language)
      this.$store.dispatch('user/SAVE_SETTING', { language })
    }
  }
};
</script>