import Crud from '../crud'
import ApiService from '@/utils/api.service'
import { API_VERSION } from '@/utils/config'

var crud = new Crud('issuers')

const state = {
  ...crud.state
}
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  FETCH_SOURCE_ADDRESSES: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/${API_VERSION}/${state.endpoint}/source-addresses`)
        .then(({ data }) => {
          commit('SET_SOURCE_ADDRESSES', data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
const mutations = {
  ...crud.mutations,
  SET_SOURCE_ADDRESSES(state, sourceAddresses) {
    state.sourceAddresses = sourceAddresses
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
