import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { API_URL } from '@/utils/config'
import JwtService from '@/utils/jwt.service'

const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },

  setHeader () {
    Vue.axios.defaults.headers.common.Authorization = 'Bearer ' + JwtService.getToken()
  },
  removeHeader () {
    delete axios.defaults.headers.common.Authorization
  },

  call (params = {}) {
    return Vue.axios(params)
  },

  query (resource, params) {
    return Vue.axios
      .get(resource, params)
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log(error.message)
        } else {
          throw new Error(`[RWV] ApiService ${error}`)
        }
      })
  },

  get (url, params = {}) {
    return Vue.axios
      .get(url, { params })
  },

  post (resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config)
  },

  patch (resource, params, config) {
    return Vue.axios.patch(`${resource}`, params, config)
  },

  update (resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put (resource, params) {
    return Vue.axios
      .put(`${resource}`, params)
  },

  delete (resource) {
    return Vue.axios
      .delete(resource)
  }
}

export default ApiService
