<template>
  <v-app>
    <core-toolbar v-if="isAuthenticated" />

    <core-drawer v-if="isAuthenticated" />

    <core-view />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import qs from 'qs'
import { CHECK_AUTH } from './store/actions/auth'
import ApiService from './utils/api.service'
import JwtService from './utils/jwt.service'
import { USER_ME_REQUEST } from './store/actions/user'

export default {
  name: 'App',
  computed: {
    ...mapGetters('auth', ['isAuthenticated'])
  },
  mounted () {
    if (this.isAuthenticated) {
      this.USER_ME_REQUEST()
    }
  },
  data () {
    return {
      isRefreshing: false,
      refreshSubscribers: []
    }
  },
  created: function () {
    ApiService.setHeader()
    axios.defaults.paramsSerializer = params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
    axios.interceptors.request.use(conf => {
      return conf
    }, err => {
      return Promise.reject(err)
    })
    const self = this
    axios.interceptors.response.use(
      response => {
        return response
      },
      err => {
        const originalRequest = err.config

        if (err?.config?.url && err.config.url.includes('auth/oauth2/token')) {
          return Promise.reject(err)
        }
        const status = err.response ? err.response.status : null
        if (status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          if (!this.isRefreshing) {
            if (this.$store.state.auth.refreshToken) {
              this.isRefreshing = true
              return self.CHECK_AUTH().then(({ data }) => {
                originalRequest.headers.Authorization = 'Bearer ' + JwtService.getToken()
                this.isRefreshing = false
                self.onRefreshed(JwtService.getToken())
                return axios.request(originalRequest)
              }, (errorRefresh) => {
                console.log('ERR')
                console.log(errorRefresh)
                this.$router.push('/login')
              })
            } else {
              console.log('NO token')
              this.$router.push('/login')
            }
          }
          const retryOrigReq = new Promise((resolve, reject) => {
            self.subscribeTokenRefresh(token => {
              // replace the expired token and retry
              originalRequest.headers.Authorization = 'Bearer ' + token
              resolve(axios(originalRequest))
            })
          })
          return retryOrigReq
        } else if (status === 422) {
          self.processError(err.response.data.messages)
          return Promise.reject(err)
        } else if (status === 400) {
          self.$toast.error(err.response.data.message)
          return Promise.reject(err)
        }
        if (!err.response && !axios.isCancel(err)) {
          // network error
          self.$toast.error('Error: Network Error')
          // self.showError('Error: Network Error')
        }
        return Promise.reject(err)
      }
    )
  },
  methods: {
    onRefreshed (token) {
      this.refreshSubscribers.map(cb => cb(token))
    },
    subscribeTokenRefresh (cb) {
      this.refreshSubscribers.push(cb)
    },
    processError (messages) {
      Object.keys(messages).forEach((key) => {
        const errors = messages[key]
        const name = typeof this.$t(`Common.${key}`) === "string" ? this.$t(`Common.${key}`) : this.$t(`Common.${key}.name`)
        if (Array.isArray(errors)) {
          this.$toast.error(`${name}: ${errors.join(',')}`)
        } else {
          this.processError(errors)
        }
      })
    },
    ...mapActions('auth', [
      CHECK_AUTH
    ]),
    ...mapActions('user', [
      USER_ME_REQUEST
    ])
  }
}
</script>

<style lang="scss">
    @import '@/styles/index.scss';

    /* Remove in 1.2 */
    .v-datatable thead th.column.sortable i {
        vertical-align: unset;
    }
</style>
