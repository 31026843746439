import Crud from '../crud'
var crud = new Crud('asset-manager-transactions')

const state = {
  ...crud.state
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions
}

const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
