import { set, toggle } from '@/utils/vuex'

const state = {
  drawer: null,
  image: 'public/img/background-drawer-jpg'
}

const mutations = {
  setDrawer: set('drawer'),
  setImage: set('image'),
  toggleDrawer: toggle('drawer')
}

export default {
  state,
  mutations
}
