/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/assetManagerTransaction/list',
    name: 'AssetManagerTransaction',
    view: 'transaction/AssetManagerTransaction'
  },
  {
    path: '/transaction/add',
    name: 'TransactionAdd',
    view: 'transaction/TransactionAdd'
  },
  {
    path: '/emails',
    name: 'EmailTable',
    view: 'emails/EmailTable'
  },
  {
    path: '/templates',
    name: 'Templates',
    view: 'templates/TemplateManager'
  },
  {
    path: '/templateProducts',
    name: 'TemplatesProducts',
    view: 'templates/TemplateProducts'
  },
  {
    path: '/search',
    name: 'SearchResult',
    view: 'search/SearchResult'
  },
  {
    path: '/pricing',
    name: 'ProductPricingList',
    view: 'pricing/list'
  },
  {
    path: '/pricing/:id',
    name: 'ProductPricingDetails',
    view: 'pricing/single'
  },
  {
    path: '/products/types',
    name: 'Products',
    view: 'product/type/ProductTypeList'
  },
  {
    path: '/products/list',
    name: 'Products',
    view: 'product/ProductList'
  },
  {
    path: '/products/from/:issuerRelationId',
    name: 'ProductFromIssuerRelation',
    view: 'product/ProductForm',
    props: true
  },
  {
    path: '/products/duplicate/:productId',
    name: 'ProductDuplicate',
    view: 'product/ProductForm',
    props: true
  },
  {
    path: '/products/:id',
    name: 'ProductsEdit',
    view: 'product/ProductForm'
  },
  {
    path: '/assetManagers',
    name: 'AssetManagers',
    view: 'assetManagers/AssetManagerList'
  },
  {
    path: '/business-introducers',
    view: 'businessIntroducers/BusinessIntroducersList'
  },
  {
    path: '/stocks/:id/mailings',
    name: 'Stocks Mailing',
    view: 'stocks/mailings'
  },
  {
    path: '/stocks',
    name: 'Stocks',
    view: 'stocks/view'
  },
  {
    path: '/prices',
    name: 'Prices',
    view: 'prices/Prices'
  },
  {
    path: '/invoice',
    view: 'invoice/InvoiceView'
  },
  {
    path: '/diagnostics',
    name: 'Diagnostics',
    view: 'Diagnostics/view'
  },
  {
    path: '/corporate-actions',
    name: 'Corporate actions',
    view: 'corporateActions/view'
  },
  {
    path: '/report-fields',
    name: 'Report fields',
    view: 'ReportFields/view'
  },
  {
    path: '/observations',
    name: 'Observations',
    view: 'observations/view'
  },
  {
    path: '/issuers',
    view: 'issuers/IssuersList'
  },
  {
    path: '/roles',
    view: 'roles/view'
  },
  {
    path: '/distribution-locations',
    view: 'distributionLocations/DistributionLocationsList'
  },
  {
    path: '/atlantic-entities',
    view: 'atlanticEntities/view'
  },
  {
    path: '/brokers',
    view: 'brokers/view'
  },
  {
    path: '/custodians',
    view: 'custodians/CustodiansList'
  },
  {
    path: '/regulators',
    name: 'Regulators',
    view: 'regulators/view'
  }
]
