import Crud from '../crud'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
import { FETCH, ADD } from '../action-types'
import { SET_MUT, ADD_MUT } from '../mutation-types'

var crud = new Crud('products/type')

const state = {
  ...crud.state
}
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  [FETCH]: ({ commit, state }) => {
    return ApiService.get(`api/${API_VERSION}/${state.endpoint}`)
      .then(({ data }) => {
        commit(SET_MUT, data)
      })
  },
  [ADD]: ({ commit, dispatch, state }, item) => {
    return new Promise((resolve, reject) => {
      ApiService
        .post(`api/${API_VERSION}/${state.endpoint}`, item)
        .then(({ data }) => {
          commit(ADD_MUT, data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
const mutations = {
  ...crud.mutations
}

export default {
  state,
  getters,
  actions,
  mutations
}
