import ApiService from '@/utils/api.service'
import { API_VERSION } from '@/utils/config'
import { STOCK_SEARCH, UPDATE_STOCK_SEARCH_MUT, FETCH_PRICE, FILL_HISTORY } from '@/store/actions/stock'

import { ADD_MUT } from '../mutation-types'

import Crud from '../crud'
var crud = new Crud('stocks')

const state = {
  ...crud.state,
  search: []
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions,
  [STOCK_SEARCH]: ({ commit, dispatch }, params) => {
    return ApiService.post(`api/${API_VERSION}/stocks/search`, params)
      .then(resp => {
        commit(STOCK_SEARCH, resp.data)
      })
  },
  [FETCH_PRICE]: (context, { id, ...params }) => {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/${API_VERSION}/stocks/price_daily/${id}`, {
        limit: 1,
        ...params
      })
        .then(({ data }) => {
          resolve(data)
        })
    })
  },
  async UPDATE_PRICE({ commit, state }, { id, last_daily_close_price, last_daily_close_datetime }) {
    const { data: last_daily_price } = await ApiService.put(`api/${API_VERSION}/stocks/price_daily/${id}`, {
      last_daily_close_price,
      last_daily_close_datetime
    })
    
    // Update stock list
    const stock = state.data.find(item => item.id === id)
    if (stock && stock.last_daily_price && stock.last_daily_price.datetime === last_daily_close_datetime) {
      commit('UPDATE_MUT', {
        ...stock,
        ...(stock.last_daily_price && stock.last_daily_price.datetime) === last_daily_close_datetime && {
          last_daily_price
        },
        ...(stock.last_price && stock.last_price.datetime.includes(last_daily_close_datetime)) && {
          last_price: {
            ...stock.last_price,
            last: last_daily_close_price
          }
        }
      })
    }
    
  },
  [FILL_HISTORY]: ({ commit }, item) => {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/${API_VERSION}/stocks/${item.stock.id}/fill_history`, item)
        .then(({ data }) => {
          resolve(data)
        })
    })
  },

  ADD_MANUAL: ({ commit, dispatch, state }, item) => {
    return new Promise((resolve, reject) => {
      ApiService
        .post(`api/${API_VERSION}/stocks/manual`, item)
        .then(({ data }) => {
          commit(ADD_MUT, data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  ...crud.mutations,
  [STOCK_SEARCH]: (state, item) => {
    state.search = item
  },
  [UPDATE_STOCK_SEARCH_MUT]: (state, info) => {
    const index = state.search.findIndex((item) => item.id === info.id)
    state.search.splice(index, 1, info)
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
