import Vue from 'vue'
import get from 'lodash/get'
import has from 'lodash/has'
import toString from 'lodash/toString'

import CRUD from '@/store/crud'
import { pricing } from '@/utils/product'

import ApiService from '@/utils/api.service'
import { API_VERSION } from '@/utils/config'

var crud = new CRUD('pricings')

const state = () => ({ 
  ...crud.state,

  issuers: []
})

const mutations = {
  ...crud.mutations,

  SET_ISSUERS(state, issuers) {
    state.issuers = issuers
  },

  UPDATE_UNKNOWN_VALUE(state, data) {
    const index = state.item.issuer_relations
      .findIndex(relation => relation.product_id === data.product.id && relation.issuer_id === data.issuer.id)
    Vue.set(state.item.issuer_relations, index, data)
  },
}

const getters = {
  ...crud.getters
}

const actions = {
  ...crud.actions,
  async FETCH_ISSUERS({ state, commit }, params = {}) {
    const { data } = await ApiService.get(`api/${API_VERSION}/${state.endpoint}/issuers`, params)
    commit('SET_ISSUERS', data)
  },
  
  async UPDATE_UNKNOWN_VALUE({ state, commit }, { product, issuer, unknownValue }) {
    const { data } = await ApiService.put(`api/${API_VERSION}/${state.endpoint}/${state.item.id}/issuer/${issuer.id}`, {
      product_id: product.id,
      unknownValue: toString(unknownValue),
      result_status: 'OK'
    })

    commit('UPDATE_UNKNOWN_VALUE', data)
  }
}

export default { 
  state,
  mutations,
  getters,
  actions
}