import toNumber from 'lodash/toNumber'
import isNil from 'lodash/isNil'

export default function(value, options = {}) {
  if (isNil(value)) return ''
  
  const { max, min } = { max: 4, min: 2, ...options}

  return toNumber(value)
    .toLocaleString('de-CH', {
      minimumFractionDigits: min > max ? max : min,
      maximumFractionDigits: max
    })
}