// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

// Plugins
import ApiService from '@/utils/api.service'

import DateFilter from '@/filter/date.filter'
import DateTimeFilter from '@/filter/dateTime.filter'
import HumanizeFilter from '@/filter/humanize.filter'
import DecimalFilter from '@/filter/decimal.filter'
import CurrencyFilter from '@/filter/currency.filter'
import OrdinalFilter from '@/filter/ordinal.filter'

import Draggable from 'vuedraggable'
import vuetify from '@/plugins/vuetify'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'

import VuetifyToast from 'vuetify-toast-snackbar-ng'

import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'

Vue.use(Vuelidate)

Vue.filter('date', DateFilter)
Vue.filter('dateTime', DateTimeFilter)
Vue.filter('decimal', DecimalFilter)
Vue.filter('currency', CurrencyFilter)
Vue.filter('humanize', HumanizeFilter)
Vue.filter('ordinal', OrdinalFilter)

Vue.component('draggable', Draggable)
Vue.use(VueCookies)
Vue.$cookies.config('1h')

Vue.use(VuetifyToast, {
  x: 'right', // default
  y: 'bottom', // default
  color: 'info', // default
  icon: 'info',
  timeout: 3000, // default
  dismissable: true, // default
  autoHeight: false, // default
  multiLine: false, // default
  vertical: false, // default
  shorts: {
    custom: {
      color: 'purple'
    }
  },
  property: '$toast' // default
})

Vue.config.productionTip = false
ApiService.init()
Vue.use(require('vue-moment'))
Vue.directive('focus', { inserted (el) { el.focus() } })
Vue.directive('selected', { inserted (el) { el.select() } })

/* eslint-disable no-new */
new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
