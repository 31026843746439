/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '../store'
import { FRONT_URL } from '@/utils/config'

// Routes
import paths from './paths'
import { loadLanguageAsync } from '../i18n'

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next()
    return
  }
  next('/login')
}

function route (path, view, name, props) {
  return {
    name: name || view,
    path,
    props: props,
    beforeEnter: ifAuthenticated,
    component: (resovle) => import(
      `@/views/${view}.vue`
    ).then(resovle)
  }
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    next()
    return
  }
  next('/products/list')
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths.map(path => route(path.path, path.view, path.name, path.props)).concat([
    { path: '*', redirect: '/products/list' },
    { path: '/platon/*', beforeEnter: (to, from, next) => {
      window.open(`${FRONT_URL}${to.params.pathMatch}`, '_blank')
      
    }},
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/Login'),
      beforeEnter: ifNotAuthenticated
    }
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const lang = store.getters['user/language']
  loadLanguageAsync(lang).then(() => next())
})
Vue.use(Meta)

export default router
