const ID_TOKEN_KEY = 'id_token'
const ID_TOKEN_REFRESH_KEY = 'id_refresh_token'

export default {
  getToken () {
    return window.localStorage.getItem(ID_TOKEN_KEY)
  },

  getRefreshToken () {
    return window.localStorage.getItem(ID_TOKEN_REFRESH_KEY)
  },

  saveToken (token, refreshToken) {
    window.localStorage.setItem(ID_TOKEN_KEY, token)
    window.localStorage.setItem(ID_TOKEN_REFRESH_KEY, refreshToken)
  },

  destroyToken () {
    window.localStorage.removeItem(ID_TOKEN_KEY)
    window.localStorage.removeItem(ID_TOKEN_REFRESH_KEY)
  }
}
