import Crud from '../crud'
import ApiService from '../../utils/api.service'
import { API_VERSION } from '../../utils/config'
import { AM_BY_PRODUCT } from '../actions/assetManager'
import Vue from 'vue'
var crud = new Crud('assetManagers')

const state = {
  ...crud.state,
  amByProduct: []
}
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  [AM_BY_PRODUCT]: ({ commit, dispatch }, productId) => {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/${API_VERSION}/assetManagers/?product_id=${productId}`)
          .then(({ data }) => {
            commit(AM_BY_PRODUCT, data)
            resolve(data)
          })
    })
  }
}
const mutations = {
  ...crud.mutations,
  [AM_BY_PRODUCT]: (state, info) => {
    Vue.set(state, 'amByProduct', info)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
