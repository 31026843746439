
export default (number) => {
  if (number === null || number === undefined) {
    return ''
  }
  const result = parseFloat(number.toString().replace(/[\']+/g, '')).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&\'')
  if (number.toString().includes('.')) {
    return result
  }
  return result.split('.')[0]
}
